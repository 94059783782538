<template>
    <header id="appHeader">
        <div class="logo" @click="mixinGoTo('homepage',{})"><SvgLogoOrange /></div>
        <div class="hamburger" @click="toggleMenu()"><SvgHamburger /></div>
        <div class="headerContent">
            <!--<div class="menuClose" @click="toggleMenu('close')"><SvgCross /></div>-->
            <Navigation />
            <User v-if="mixinGetIntegrationInfo(0).integration"/>
        </div>
    </header>
</template>

<script>
import SvgHamburger from '../../svg/hamburger'
//import SvgCross from '../../svg/cross'
import SvgLogoOrange from '../../svg/logo-orange'
import Navigation from './navigation'
import User from './user'
export default {
    name: 'page-header',
    components:
    {
        SvgHamburger,
        //SvgCross,
        SvgLogoOrange,
        Navigation,
        User,
    },
    data()
    {
      return {
        menuOpen: false,
      }
    },
    mounted()
    {
    },
    methods:
    {
        toggleMenu()
        {

          (this.menuOpen !== true)? this.openMenu() : this.closeMenu()

        }, // toggleMenu

        openMenu(){

          if(this.menuOpen !== true) {
            this.menuOpen = true
            document.body.classList.add('transitionIn')
            document.body.classList.add('menuOpen')
            setTimeout(function () {
              document.body.classList.remove('transitionIn')
            }, 25)
          }

        }, // openMenu

        closeMenu(){

          if(this.menuOpen !== false) {
            this.menuOpen = false
            document.body.classList.add('transitionOut')
            setTimeout(function () {
              document.body.classList.remove('menuOpen')
              document.body.classList.remove('transitionOut')
            }, 500)
          }

        }, // closeMenu
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    header
    {
        background:$light-gray;//$white;
        border-bottom:1px solid rgba($medium-gray,0.25);
        height:$topbar-mobile-header-height;
        padding:$grid-gutter-width/4 $grid-gutter-width/2;
        display:flex;
        justify-content: space-between;
        align-items:center;
        
        .logo
        {
            position:relative;
            z-index:1024;
            cursor:pointer;
            height:100%;
            svg
            {
                height:100%;
                width:auto;
            }
        }
        .hamburger
        {
            position:relative;
            z-index:1024;
            width:20px;
            cursor:pointer;
            svg
            {
                width:100%;
                height:auto;
                transition:$transition-base;
                transform:none;
            }
            path
            {
                transition:$transition-base;
                opacity:1;
                transform:none;
            }            
        }
        .headerContent
        {
            display:none;
            flex-direction: column;
            align-items:center;
            justify-content:center;
            position:relative;
            padding:$grid-gutter-width $grid-gutter-width/2;
            position:fixed;
            top:0;
            left:0;
            width:100vw;
            height:100vh;
            z-index: 1023;
            background:$light-gray;//$white;
        }
        /*
        .menuClose
        {
            position:absolute;
            top:$grid-gutter-width/4;
            right:$grid-gutter-width/2;
            cursor:pointer;
            svg *
            {
                fill:$black;
            }
        }*/
        .navigation
        {
        }
        .user
        {
        }

        @include media-breakpoint-up(lg)
        {
            height:$topbar-desktop-header-height;
            .headerContent
            {
                display:flex;
                flex-direction:row;
                justify-content:flex-end;
                flex:1;
                position:relative;
                height:auto;
                width:auto;
                margin:0;
                padding:0;
            }
            .menuClose,
            .hamburger
            {
                display:none;
            }
            .navigation
            {
                .links
                {
                }
            }
            .user
            {

            }
        }
    }
    body.menuOpen
    {
        overflow:hidden;
        
        &.transitionIn,
        &.transitionOut
        {
            .headerContent
            {
                opacity:0;
                transform:translateY(1rem);
            }
        }
        .headerContent
        {
            display:flex;
            transform:none;
            transition:$transition-base;
        }
        .hamburger svg
        {
            transform:translateY(-2px);
            path:nth-child(2)
            {
                transform: translateY(13px) translateX(-10px) rotate(45deg);
                transform-origin: center center;
            }
            path:nth-child(1)
            {
                opacity:0;
            }
            path:nth-child(3)
            {
                transform-origin: center center;
                transform: translateY(-21px) rotate(-45deg) translateX(-15px);
            }
        }
    }
</style>