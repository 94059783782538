var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "45",
        height: "49",
        viewBox: "0 0 45 49",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M 42.29 22.752 L 3.043 22.752 C 2.768 22.746 2.494 22.795 2.237 22.896 C 1.981 22.998 1.748 23.149 1.551 23.342 C 1.354 23.534 1.197 23.764 1.09 24.018 C 0.983 24.272 0.928 24.545 0.928 24.821 C 0.928 25.096 0.983 25.369 1.09 25.623 C 1.197 25.877 1.354 26.107 1.551 26.3 C 1.748 26.492 1.981 26.644 2.237 26.745 C 2.494 26.846 2.768 26.895 3.043 26.889 L 42.235 26.889 C 42.507 26.89 42.776 26.837 43.027 26.733 C 43.278 26.629 43.507 26.477 43.699 26.285 C 43.891 26.093 44.043 25.865 44.147 25.613 C 44.251 25.362 44.304 25.093 44.303 24.821 C 44.315 24.551 44.271 24.281 44.175 24.029 C 44.079 23.776 43.932 23.546 43.743 23.352 C 43.555 23.158 43.328 23.005 43.079 22.902 C 42.829 22.799 42.56 22.748 42.29 22.752 Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M 3.043 10.508 L 42.289 10.508 C 42.561 10.509 42.83 10.456 43.081 10.353 C 43.333 10.249 43.561 10.097 43.753 9.904 C 43.946 9.712 44.098 9.484 44.202 9.233 C 44.306 8.981 44.359 8.712 44.358 8.44 C 44.363 8.167 44.314 7.896 44.212 7.642 C 44.11 7.389 43.957 7.158 43.764 6.965 C 43.571 6.772 43.341 6.62 43.087 6.518 C 42.834 6.416 42.562 6.366 42.289 6.371 L 3.043 6.371 C 2.771 6.371 2.502 6.423 2.25 6.527 C 1.999 6.631 1.77 6.783 1.578 6.976 C 1.386 7.168 1.233 7.396 1.13 7.647 C 1.026 7.899 0.973 8.168 0.974 8.44 C 0.969 8.713 1.019 8.985 1.121 9.238 C 1.223 9.491 1.375 9.722 1.568 9.915 C 1.761 10.108 1.992 10.26 2.245 10.362 C 2.498 10.464 2.77 10.514 3.043 10.508 Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M 42.29 39.133 L 3.044 39.133 C 2.772 39.132 2.503 39.186 2.251 39.289 C 2 39.393 1.772 39.545 1.579 39.738 C 1.387 39.93 1.235 40.158 1.131 40.409 C 1.027 40.661 0.974 40.93 0.975 41.202 C 0.97 41.475 1.02 41.747 1.122 42 C 1.224 42.253 1.376 42.484 1.569 42.677 C 1.762 42.87 1.993 43.022 2.246 43.124 C 2.499 43.226 2.771 43.276 3.044 43.271 L 42.29 43.271 C 42.562 43.272 42.831 43.219 43.083 43.115 C 43.334 43.011 43.562 42.859 43.754 42.667 C 43.947 42.474 44.099 42.246 44.203 41.995 C 44.307 41.743 44.36 41.474 44.359 41.202 C 44.364 40.929 44.314 40.658 44.212 40.404 C 44.11 40.151 43.958 39.921 43.765 39.727 C 43.572 39.534 43.341 39.382 43.088 39.28 C 42.835 39.178 42.563 39.128 42.29 39.133 Z",
          fill: "#333333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }