var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { attrs: { id: "appHeader" } }, [
    _c(
      "div",
      {
        staticClass: "logo",
        on: {
          click: function ($event) {
            return _vm.mixinGoTo("homepage", {})
          },
        },
      },
      [_c("SvgLogoOrange")],
      1
    ),
    _c(
      "div",
      {
        staticClass: "hamburger",
        on: {
          click: function ($event) {
            return _vm.toggleMenu()
          },
        },
      },
      [_c("SvgHamburger")],
      1
    ),
    _c(
      "div",
      { staticClass: "headerContent" },
      [
        _c("Navigation"),
        _vm.mixinGetIntegrationInfo(0).integration ? _c("User") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }